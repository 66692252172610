import React from "react"
import {
  Box,
  Button,
  Heading,
  Icon,
  Link,
  Text,
  useTheme,
} from "@chakra-ui/core"
import { Link as GatsbyLink } from "gatsby"

import Layout from "../components/layout"

const Hoidot = (props) => {
  const theme = useTheme()
  return (
    <Layout>
      <Box w="100%" p="6">
        <Box maxW={theme.breakpoints.lg} mx="auto">
          <Heading textAlign="center" color="pink.800" mb="4">
            Onnistua.fi -kauneushoitola toimii kosmetologiopiskelijoiden
            työssäoppimispaikkana.
          </Heading>
          <Text color="pink.800" fontSize="lg" mb="6">
            Hoitolassa opiskelijat pääsevät tutustumaan yrittäjyyteen ja he
            saavat tehdä paljon hoitoja jolloin asiakaspalvelu ja myynti tulevat
            tutuksi. Oppilaat ovat kertoneet huomanneensa paljon uusia asioita
            yrittäjyydestä ja saaneensa myönteisemmän kuvan yrittäjänä
            toimimisesta. Olen iloinen että olen pystynyt rohkaisemaan
            opiskelijoita kokeilemaan siipiään.
          </Text>
          <Text color="pink.800" fontSize="lg" mb="6">
            TOP-jaksot kestävät neljästä kahdeksaan viikkoa, mutta sovitusti
            opiskelun voi suorittaa pääsääntöisesti Onnistua.fi -hoitolassa. On
            mahtava huomata miten paljon opiskelijat kehittyvät lyhyessäkin
            ajassa, kun he pääsevät aitoon hoitolaympäristoon ja työn tuntuun.
            Ilman meidän mahtavia asiakkaita opiskelijat eivät pääsisi
            harjoittelemaan kuin toisilleen. Kiitos asiakkaillemme ja niille
            jotka aikovat tulla meille hoitoon.
          </Text>

          <Text color="pink.800" fontSize="lg" mb="6">
            Asiakkaiden on myös mahdollista varata aika yrittäjä Vilmalle. Hän
            tekee volyymiripsenpidennyksiä, vaativampia sokerointeja,
            mikroneulausta yms.
          </Text>
          <Text color="pink.800" fontSize="lg" mb="6">
            Opiskelijaksi meille voit hakea{" "}
            <Link as={GatsbyLink} color="pink.500" to="/ota-yhteytta">
              laittamalla viestiä sähköpostitse, puhelimitse tai
              yhteydenottolomakkeen kautta
            </Link>
            .
          </Text>

          <Text color="pink.800" fontSize="lg" mb="6">
            Hius- ja kauneudenhoitoalan perustutkinto on tavallisesti kolme
            vuotta kestävä ammatillinen koulutus. Oppilaiden edellytetään
            suorittavan opintojen aikana useita työharjoittelujaksoja.
            Työpaikkojen löytäminen on haasteellista, sillä kauneusalalla
            tyypillistä on toimia pienyrittäjänä, jolloin toimipaikan neliöt
            rajoittavat oppilaiden työllistämistä.
          </Text>

          <Text color="pink.800" fontSize="lg" mb="6">
            Haluan ratkaista tämän. Näkemykseni mukaan tämä on sosiaalinen ja
            yhteiskunnallinen ongelma, joka voi vaikuttaa nuoren aikuisen
            ammattitaitoon myös valmistumisen jälkeen. Opintojen aikana
            kerrytetty työkokemus on tarpeellista ja toivon oppilaiden saavan
            monipuolisen ja kannustavan työharjoittelun, joka antaa hyvät
            valmiudet vaikkapa oman yrityksen perustamiseen..
          </Text>
          <Text color="pink.800" fontSize="lg" mb="6">
            <Link
              color="pink.500"
              href="https://eperusteet.opintopolku.fi/#/fi/esitys/3855076/reformi/rakenne"
              isExternal
            >
              Lue täältä <Icon name="external-link" mx="2px" />
            </Link>{" "}
            millaisia asioita kauneudenhoitoalan oppilaat käyvät läpi
            ammattiopistossa.
          </Text>
          <Text color="pink.800" fontSize="lg" mb="6">
            <Link
              color="pink.500"
              href="https://www.aamulehti.fi/kotimaa/ammattikoulu-kokee-ensi-vuonna-jattiremontin-katso-tasta-mika-muuttuu-200323082"
              isExternal
            >
              Lue täältä <Icon name="external-link" mx="2px" />
            </Link>{" "}
            mitä ammattikoulun vuoden 2018 reformi tarkoittaa käytännössä
            oppilaiden arjessa.
          </Text>
        </Box>
      </Box>
    </Layout>
  )
}

export default Hoidot
